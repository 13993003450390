<template>
	<div class="container">
		<van-empty description="您访问的页面不存在~" />
		<el-button @click="$router.push('/')">返回首页</el-button>
	</div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
	font-size: 32px;
	text-align: center;
	margin-top: 100px;
}
</style>
